import ProductListQuery from 'Query/ProductList.query';
import {
    ProductListDispatcher as SourceProductListDispatcher,
} from 'SourceStore/ProductList/ProductList.dispatcher';
import React from 'react';
import { appendPage, updateLoadStatus, updatePageLoadingStatus, updateProductListItems } from '@scandipwa/scandipwa/src/store/ProductList/ProductList.action';
import NewProductlist from 'Query/NewProductlist.query';
import { showNotification } from '@scandipwa/scandipwa/src/store/Notification/Notification.action';
import { NotificationType } from '@scandipwa/scandipwa/src/type/NotificationList.type';
import { updateNoMatch } from '@scandipwa/scandipwa/src/store/NoMatch/NoMatch.action';

export class ProductListDispatcher extends SourceProductListDispatcher {
    onSuccess(
        data,
        dispatch,
        options,
    ) {
        const {
            getProductsByCategoryId: {
                items = [],
                total_count = 0,
                attributes
                // page_info: { total_pages = 0 } = {},
            } = {},
        } = data;

        const { args = {}, isNext } = options;
        const { currentPage = 0, pageSize = 12 } = args;

        dispatch(updateLoadStatus(false));

        const total_pages = total_count > 0 ? Math.ceil(total_count / pageSize) : 0;
        // console.log(attributes, "==>options");
        if (isNext) {
            return dispatch(
                appendPage(
                    items,
                    currentPage,
                ),
            );
        }

        return dispatch(
            updateProductListItems(
                items,
                currentPage,
                total_count,
                total_pages,
                args,
                attributes
            ),
        );
    }

    onError(error, dispatch) {
        dispatch(showNotification(NotificationType.ERROR, ('Error fetching Product List!'), error));
        dispatch(updateNoMatch(true));
    }


    prepareRequest(
        options,
        dispatch,
    ) {
        const { isNext } = options;

        if (!isNext) {
            dispatch(updateLoadStatus(true));
        } else {
            dispatch(updatePageLoadingStatus());
        }

        return NewProductlist.getQuery(options);
    }
}

export default new ProductListDispatcher();
