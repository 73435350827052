// import { Field } from 'Util/Query';
import { Field, InlineFragment, Query } from '@tilework/opus';

export class NewProductlist {
    options = {};

    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        return this._getProductsField();
    }

    _getProductsField() {
        const { args: {
            pageSize,
            currentPage,
            sort: {
                sortDirection,
                sortKey
            },
            filter: {
                categoryIds,
                customFilters
            }
        } } = this.options;

        let pricefrom = 0;
        let priceto = 0;
        let filter = {};

        if (customFilters?.price) {
            const { price } = customFilters;
            if (price?.length > 0) {
                const minmiaxprice = price[0]?.split("_");

                if (minmiaxprice?.length > 0) {
                    pricefrom = minmiaxprice[0];
                    priceto = minmiaxprice[1];
                }
            }
        }

        const customFiltersKey = Object.keys(customFilters);

        if (customFiltersKey?.length > 0) {
            const data = customFiltersKey?.map((value) => {
                if (value !== "price") {
                    if (customFilters[value]?.length > 1) {
                        filter = { ...filter, [value]: { in: customFilters[value] } }
                    } else {
                        filter = { ...filter, [value]: { eq: customFilters[value][0] } }
                    }
                }
            })
        }

        const products = new Query('getProductsByCategoryId')
            .addArgument('pageSize', 'Int', pageSize)
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('categoryId', 'Int', categoryIds)
            .addArgument('sort', 'SortInput', { direction: sortDirection, field: sortKey })
            .addArgument('priceRange', 'PriceInput', { from: pricefrom, to: priceto })
            .addArgument('filter', 'ProductAttributeFilterInput', filter)
            .addFieldList(this._getProductFields());

        return products;
    }

    _getProductFields() {
        return [
            'total_count',
            this._getAttributes(),
            this._getProducts()
        ];
    }

    _getAttributes() {
        return new Field('attributes')
            .addFieldList([
                'attribute_id',
                'attribute_code',
                'frontend_label',
                this._getAttributesOptions()
            ]);
    }

    _getAttributesOptions() {
        return new Field('options')
            .addFieldList([
                'value',
                'label',
            ]);
    }

    _getProducts() {
        return new Field('items')
            .addFieldList([
                'id',
                'name',
                'status',
                'sku',
                this._getThumbnailUrl(),
                this._getPrice(),
                this._getVariantsProducts(),
                this._getSelectedOption(),
                this._getConfigureOption()
            ]);
    }

    _getSelectedOption() {
        return new Field('selected_option')
            .addFieldList([
                'attribute_code',
                'value'
            ]);
    }

    _getConfigureOption() {
        return new Field('configure_variations')
            .addFieldList([
                'code',
                'value'
            ]);
    }

    _getThumbnailUrl() {
        return new Field('mediaGallery')
            .addFieldList([
                'url'
            ]);
    }

    _getPrice() {
        return new Field('price')
            .addFieldList([
                'price',
                'final_price'
            ]);
    }

    _getVariantsProducts() {
        return new Field('variants')
            .addFieldList([
                'attribute_code',
                'attribute_options',
            ]);
    }
}

export default new NewProductlist();
